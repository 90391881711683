import React, { useState, useRef } from "react";
import {
  Steps,
  Nav,
  Navbar,
  Panel,
  Placeholder,
  ButtonGroup,
  Input,
  Button,
  Row,
  Col,
  Grid,
  Form,
  InputGroup,
  ButtonToolbar,
  Schema,
  FlexboxGrid,
  SelectPicker,
  CheckPicker,
} from "rsuite";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { JSONTree } from "react-json-tree";
import TechnologyData from "./data.json";
import Swal from "sweetalert2";
import { postData } from "../Services/NodeServices";
import Lottie from "react-lottie";
import * as animationData from "./Hiring.json";
import OTPInput, { ResendOTP } from "otp-input-react";
import HomeIcon from "@rsuite/icons/legacy/Home";
import CogIcon from "@rsuite/icons/legacy/Globe2";

// const JSONView = ({ formValue, formError }) => (
//   <div style={{ marginBottom: 10 }}>
//     <Panel className="json-tree-wrapper" header={<p>formValue</p>}>
//       <JSONTree data={formValue} />
//     </Panel>

//     <Panel className="json-tree-wrapper" header={<p>formError</p>}>
//       <JSONTree data={formError} />
//     </Panel>
//   </div>
// );

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired("This field is required."),
  designation: StringType().isRequired("This field is required."),
  company: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  mobile: StringType()
    .minLength(10, "Minimum 10 number required")
    .maxLength(10, "Maximum 10 number required")
    .isRequired("This field required"),
});

const model2 = Schema.Model({
  name: StringType().isRequired("This field is required."),
  designation: StringType().isRequired("This field is required."),
  company: StringType().isRequired("This field is required."),
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  mobile: StringType()
    .minLength(10, "Minimum 10 number required")
    .maxLength(10, "Maximum 10 number required")
    .isRequired("This field required"),
  // roles: StringType().isRequired('This field is required.'),
  // technology: StringType().isRequired('This field is required.'),
  positions: StringType().isRequired("This field required"),
  timelines: StringType().isRequired("This field required"),
});

const TextField = React.forwardRef((props, ref) => {
  const { name, label, accepter, ...rest } = props;
  return (
    <Form.Group controlId={`${name}-4`} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
    </Form.Group>
  );
});
export default function HireFromUs() {
  const developerType = Object.keys(TechnologyData);

  const styles = {
    marginBottom: 10,
  };
  const bodypadding = {
    padding: "1.2%",
    // height: '100vh',
    // width: '90%'
  };
  const [step, setStep] = React.useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 1 ? 1 : nextStep);
  };

  const formRef = React.useRef();
  const [formError, setFormError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [formValue, setFormValue] = React.useState({
    name: "",
    designation: "",
    company: "",
    email: "",
    mobile: "",
    roles: "",
    technology: "",
    positions: "",
    timelines: "",
  });
  const [getDeveloperType, setDeveloperType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [getTechnology, setTechnology] = useState([]);
  const [OTP, setOTP] = React.useState("");
  const [btnStatus, setBtnStatus] = useState(true);
  const [gOtp, setGOtp] = useState("");
  const [mobileno, setMobileno] = useState();
  const mobileRef = useRef();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    } else {
      if (step == 0) {
        if (OTP) {
          if (OTP == gOtp) {
            onNext();
          } else {
            Swal.fire({
              icon: "error",
              title: "Incorrect OTP",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Please Verify Mobile Number",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else if (!getDeveloperType) {
        Swal.fire({
          title: "Please select Role you're hiring for",
          icon: "error",
        });
      } else {
        // alert(JSON.stringify(formValue))
        setLoading(true);
        var data = [];
        data = {
          ...formValue,
          roles: getDeveloperType,
          technology: getTechnology.join(),
        };
        let result = await postData("hiring/create", data);
        if (result.status) {
          Swal.fire({
            icon: "success",
            title: "Hiring Enquiry Successfully",
            text: result.message,
            timer: 3000,
            timerProgressBar: true,
          });
          setLoading(false);
          setTimeout(() => {
            window.location.href = "https://sandeepsappal.in/";
          }, 1500);
        } else {
          Swal.fire({
            icon: "error",
            title: "Server error!",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    }
    console.log(formValue, "Form Value");
  };

  const handleCheckEmail = () => {
    formRef.current.checkForField("email", (checkResult) => {
      console.log(checkResult);
    });
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const filterTech = (e) => {
    if (e) {
      setFilteredData(TechnologyData[e]);
    }
  };

  const generateOtp = () => {
    var otp = parseInt(Math.random() * 899999) + 100000;
    return otp;
  };

  const handleVerify = async () => {
    var t = generateOtp();
    setBtnStatus(false);
    setOTP(t);
    setGOtp(t);
    const body = {
      mobile: formValue.mobile,
      msg: t,
    };
    const result = await postData("api/sendotphiring", body);
    if (result.status) {
      Swal.fire({
        icon: "success",
        title: "OTP sent successfully",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleResendOtp = async () => {
    var t = generateOtp();
    const body = {
      mobile: formValue.mobile,
      msg: t,
    };
    setGOtp(t);
    const result = await postData("api/sendotphiring", body);
    if (result.status) {
      setOTP(t);
      setGOtp(t);

      Swal.fire({
        icon: "success",
        title: "OTP resend successfully",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleChangeSubmit = () => {
    setBtnStatus(true);
    setOTP("");
    setGOtp("");
  };

  return (
    <div style={{ background: "#fff", position: "relative" }}>
      <Navbar
        appearance="subtle"
        style={{ borderBottom: "1px solid #e7e7e7", padding: 10 }}
      >
        {/* <Navbar.Brand href="#"><img src="/login-form-17/images/logo.png" width={200} /></Navbar.Brand> */}
        <Nav>
          <Nav.Item eventKey="1">
            <img src="/login-form-17/images/logo.png" width={180} />
          </Nav.Item>
          {/* <Nav.Item eventKey="2">News</Nav.Item>
          <Nav.Item eventKey="3">Products</Nav.Item>
          <Nav.Menu title="About">
            <Nav.Item eventKey="4">Company</Nav.Item>
            <Nav.Item eventKey="5">Team</Nav.Item>
            <Nav.Item eventKey="6">Contact</Nav.Item>
          </Nav.Menu> */}
        </Nav>
        <Nav pullRight>
          <Nav.Item href="https://sandeepsappal.in" icon={<CogIcon />}>
            Go To Website
          </Nav.Item>
        </Nav>
      </Navbar>
      <Grid fluid gutter={3} style={bodypadding}>
        <Row className="show-grid">
          <Col xs={24} sm={24} md={16}>
            {/* <div><img src="/login-form-17/images/Hire-From-Us-MP4.gif" style={{ width: '75%', textAlign: 'center' }} /></div> */}
            <h4 style={{ paddingBottom: "5%" }}>Hire From Us</h4>
            {/* <hr /> */}
            <div style={{ display: "flex" }}>
              <Steps vertical current={0} style={styles}>
                <Steps.Item title="Fill this form." />
                <Steps.Item title="Our Partnership Team will understand your needs" />
                <Steps.Item title="Receive profiles tailored to your request." />
                <Steps.Item title="Interview the candidate as per your processes." />
                <Steps.Item title="Roll out your offer." />
                <Steps.Item title="Candidate joins immediately." />
              </Steps>
              <Lottie options={defaultOptions} height={"60%"} width={"60%"} />
            </div>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              borderRadius: 10,
              padding: 20,
            }}
          >
            <Steps current={step}>
              <Steps.Item title="In Progress" />
              <Steps.Item title="Finished" />
              {/* <Steps.Item title="Waiting" description="Description" />  */}
            </Steps>
            <hr />
            {/* <hr /> */}
            <Form
              ref={formRef}
              onChange={setFormValue}
              onCheck={setFormError}
              formValue={formValue}
              model={step == 0 ? model : model2}
              fluid
            >
              {step == 0 ? (
                <>
                  <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                      <h5 style={{ marginBottom: 10 }}>Personal Detail</h5>
                      <TextField name="name" Placeholder="Name" />
                      <TextField name="designation" Placeholder="Designation" />
                      <TextField name="company" Placeholder="Company Name" />
                      <TextField name="email" Placeholder="Email" />
                      {OTP ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 20,
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <TextField
                              name="mobile"
                              type="number"
                              style={{ width: 260 }}
                              disabled
                              Placeholder="Phone"
                            />
                          </div>
                          <div>
                            <Button
                              appearance="primary"
                              onClick={() => {
                                handleChangeSubmit();
                              }}
                              style={{ marginTop: 2 }}
                            >
                              Change Number
                            </Button>
                          </div>
                        </div>
                      ) : formValue.mobile.length == 10 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 20,
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <TextField
                                name="mobile"
                                type="number"
                                style={{ width: 330 }}
                                Placeholder="Phone"
                              />
                            </div>
                            <div>
                              <Button
                                appearance="primary"
                                onClick={() => handleVerify()}
                                style={{ marginTop: 2 }}
                              >
                                Verify
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <TextField
                            name="mobile"
                            type="number"
                            ref={mobileRef}
                            style={{ width: "100%" }}
                            Placeholder="Phone"
                          />
                          {/* <span onClick={() => {
                            handleChangeSubmit()
                          }} className="form-control btn btn-primary submit px-3">
                            Change Mobile No.
                          </span> */}
                        </>
                      )}
                      {btnStatus ? (
                        <></>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>VERIFICATION NUMBER</div>
                            <div
                              onClick={() => handleResendOtp()}
                              className="btn btn-primary submit px-2"
                              style={{ fontSize: 12 }}
                            >
                              Resend Otp
                            </div>
                          </div>
                          <div
                            style={{ fontSize: 10, margin: 0 }}
                          >{`We have sent 6 digits otp on +91${formValue.mobile}`}</div>
                          <OTPInput
                            style={{ marginTop: 10 }}
                            inputStyles={
                              {
                                // width: 15,
                                // height: 15,
                                // marginRight: '5px',
                                // fontSize: '1rem',
                                // borderRadius: 4,
                                // border: '1px solid rgba(0,0,0,0.3)',
                              }
                            }
                            value={gOtp}
                            onChange={setGOtp}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                          />
                        </>
                      )}
                      {/* <ButtonToolbar>
                      <Button appearance="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                      <Button onClick={handleCheckEmail}>Check Email</Button>
                    </ButtonToolbar> */}
                    </FlexboxGrid.Item>
                    {/* <FlexboxGrid.Item colspan={12}>
                  <JSONView formValue={formValue} formError={formError} />
                </FlexboxGrid.Item> */}
                  </FlexboxGrid>
                  {/* <Grid fluid spacing={12}>
                <Row className="show-grid" spacing={12}>
                  <Col xs={24} gutter="5px" spacing={12}>
                    <Input placeholder="Name"  />
                    <Input placeholder="Designation"  />
                    <Input placeholder="Company"  />
                    <Input placeholder="Work E-mail"  />
                    <Input placeholder="Mobile No."  />
                  </Col>
                </Row>
              </Grid> */}
                </>
              ) : (
                <>
                  <Row className="show-grid">
                    <Col xs={24}>
                      <h5 style={{ marginBottom: 10 }}>Hiring Details</h5>
                      <p>Role You Are Hiring For</p>
                      <SelectPicker
                        name="roles"
                        cleanable={false}
                        data={developerType.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={(e) => {
                          filterTech(e);
                          setDeveloperType(e);
                        }}
                        block
                        style={styles}
                      />
                      {filteredData.length > 0 ? (
                        <>
                          <p>Technology</p>
                          <CheckPicker
                            name="technology"
                            onChange={setTechnology}
                            data={filteredData.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            block
                            style={styles}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <TextField
                        name="positions"
                        label="Number of Positions"
                        isRequired={true}
                      />
                      <TextField
                        name="timelines"
                        label="Timelines (in days)"
                        isRequired={true}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <hr />

              <ButtonGroup>
                <Button onClick={onPrevious} disabled={step === 0}>
                  Previous
                </Button>
                <Button
                  appearance="primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={loading}
                >
                  {step === 0 ? <>Continue</> : <>Submit</>}
                </Button>
              </ButtonGroup>
            </Form>
            {/* <ButtonGroup>
              <Button onClick={onPrevious} disabled={step === 0}>
                Previous
              </Button>
              <Button onClick={onNext} disabled={step === 3}>
                Next
              </Button>
            </ButtonGroup> */}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

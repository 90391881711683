import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Enquiry from "./Components/UserInterface/Enquiry";
import HireFromUs from "./Components/UserInterface/HireFromUs";
function App(props) {
  return (
    <Router>
      <Routes>
        <Route element={<Enquiry />} path="/" />
        <Route element={<HireFromUs />} path="/hirefromus" />
      </Routes>
    </Router>
  );
}

export default App;

const axios = require("axios");
// const BaseUrl = "http://localhost:3002";
// const ClientURL = "http://localhost:3000";

const BaseUrl = "https://campusshala.com:3006";
const ClientURL = "https://enquiry.sandeepsappal.in";

const postDataImage = async (url, formData) => {
  try {
    const response = await axios.post(`${BaseUrl}/${url}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    var result = response.data;
    return result;
  } catch (error) {
    return error.response.data;
  }
};

const getData = async (url) => {
  try {
    var response = await fetch(`${BaseUrl}/${url}`, {
      method: "Get",
      mode: "cors",
      headers: { "content-type": "application/json ; charset=utf-8" },
    });
    var result = await response.json();
    return result;
  } catch (e) {
    return false;
  }
};

const postData = async (url, body) => {
  try {
    var response = await fetch(`${BaseUrl}/${url}`, {
      method: "post",
      mode: "cors",
      body: JSON.stringify(body),
      headers: { "content-type": "application/json;charset=utf-8" },
    });
    var result = await response.json();
    return result;
  } catch (e) {
    return false;
  }
};

export const putData = async (url, body) => {
  try {
    var response = await axios.put(`${BaseUrl}/${url}`, body, {
      headers: { "content-type": "application/json;charset=utf-8" },
    });
    var result = response.data;
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteData = async (url, body) => {
  try {
    var response = await axios.delete(`${BaseUrl}/${url}`, {
      headers: { "content-type": "application/json;charset=utf-8" },
    });
    var result = response.data;
    return result;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getData,
  BaseUrl,
  postData,
  ClientURL,
  postDataImage,
};

import React, { useState, useEffect, useRef } from "react";
import { getData, postData } from "../Services/NodeServices";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from "react-select";
import OTPInput, { ResendOTP } from "otp-input-react";

export default function Home(props) {
  const [technology, Technology] = React.useState([]);
  const [OTP, setOTP] = React.useState("");
  const [btnStatus, setBtnStatus] = useState(true);
  const [gOtp, setGOtp] = useState("");
  const [rgOtp, setRGOtp] = useState("");
  const [rbtnStatus, setRBtnStatus] = useState(true);
  const [mobileno, setMobileno] = useState();
  const mobileRef = useRef();

  const generateOtp = () => {
    var otp = parseInt(Math.random() * 899999) + 100000;
    return otp;
  };

  const handleChkOtp = () => {
    if (OTP == gOtp) {
    } else {
      alert("Invalid otp");
    }
  };

  const readAllTechnology = async () => {
    let result = await getData("technology/displayall");
    if (result && result.length) {
      Technology(result);
    }
  };

  useEffect(function () {
    readAllTechnology();
    $(document).ready(function () {
      $(".dropdown ul>li").click(function () {
        $(".dropdown ul>li").each(function () {
          $(this).removeClass("drop-selected");
        });
        $(this).toggleClass("drop-selected");
        $(".dropdown>span").text($(this).attr("val").val("value"));
      });
    });
  }, []);

  const handleVerify = async (e) => {
    // const result = await postData('users/checkmobile', { mobileno: mobileno })
    // e.preventDefault();
    // const data = new FormData(e.currentTarget);
    // if (data.get("mobile") == null) {
    //   alert("error")
    // }
    // else {
    //   if (btnStatus) {
    //     setBtnStatus(false)
    //     // var t = generateOtp()
    //     // alert(t)
    //     // setGOtp(t)
    //   }
    //   else {
    //     var t = generateOtp()
    //     alert(t)
    //     setRGOtp(t)
    //   }
    // }
  };

  const handleResendOtp = async () => {
    var t = generateOtp();
    const body = {
      mobile: mobileRef.current.value,
      msg: t,
    };
    const result = await postData("api/sendotp", body);
    setOTP(t);
    setGOtp(t);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("mobile"));
    var t = generateOtp();
    if (btnStatus) {
      setBtnStatus(false);
      const body = {
        mobile: data.get("mobile"),
        msg: t,
      };
      const result = await postData("api/sendotp", body);
      setOTP(t);
      setGOtp(t);
    } else {
      console.log(OTP);
      console.log(gOtp);
      if (OTP == gOtp) {
        let body = {
          technologyid: data.get("technology"),
          name: data.get("name"),
          email: data.get("email"),
          mobile: data.get("mobile"),
        };
        let result = await postData("enquiry/enquiry", body);
        if (result.status) {
          Swal.fire({
            icon: "success",
            text: result.message,
          });
          setTimeout(() => {
            window.location.href = "https://sandeepsappal.in/";
          }, 1500);
        } else {
          Swal.fire({
            icon: "error",
            title: result.message || "Server Error!",
          });
        }
      } else {
        alert("OTP INCORRECT...");
      }
    }
  };
  var options = technology.map((item) => ({
    value: item.technologyid,
    label: item.technologyname,
  }));
  return (
    <>
      {/* <video
        playsinline=""
        autoplay=""
        muted=""
        loop=""
        // poster="login-form-17/images/bg-02.jpg"
        id="bgvid"
        __idm_id__="10944513"
      >
        <source src="Placements.mp4" type="video/mp4" /> */}

      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last"></div>
                <div className="login-wrap p-4 p-lg-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Enquiry Form</h3>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="signin-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Technology
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="technology"
                        options={options}
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        Whatsapp No.
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        ref={mobileRef}
                        title="starting with 9 or 8 or 7 or 6 and other 9 digit using any number"
                        maxLength={10}
                        minLength={10}
                        onChange={(e) => setMobileno(e.target.value)}
                        pattern="[6789][0-9]{9}"
                        className="form-control"
                        placeholder="Whatsapp No."
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      {btnStatus ? (
                        <></>
                      ) : (
                        // <button
                        //   // type="submit"
                        //   onClick={() => handleVerify()}
                        //   className="form-control btn btn-primary submit px-3"
                        // >
                        //   Verify
                        // </button>
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>VERIFICATION NUMBER</div>
                            <div
                              onClick={() => handleResendOtp()}
                              className="btn btn-primary submit px-2"
                              style={{ fontSize: 12 }}
                            >
                              Resend Otp
                            </div>
                          </div>
                          <div
                            style={{ fontSize: 10, margin: 0 }}
                          >{`We have sent 6 digits otp on +91${mobileno}`}</div>
                          <OTPInput
                            style={{ marginTop: 10 }}
                            inputStyles={
                              {
                                // width: 15,
                                // height: 15,
                                // marginRight: '5px',
                                // fontSize: '1rem',
                                // borderRadius: 4,
                                // border: '1px solid rgba(0,0,0,0.3)',
                              }
                            }
                            value={gOtp}
                            onChange={setGOtp}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                          />
                        </>
                      )}
                    </div>
                    <div className="">
                      <button
                        type="submit"
                        className="form-control btn btn-primary submit px-3"
                      >
                        Verify Mobile No. & Submit Query
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </video> */}
    </>
  );
}
